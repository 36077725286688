<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0">
      <v-row justify="center" class="mx-0">
        <v-col cols="12">
          <v-card class="pl-2 pr-2">
            <v-row>
              <v-tabs class="table_tabs_hp ml-3 mt-3 mr-3">
                <v-tab to="/">Overview</v-tab>
                <!-- <v-tab to="/videolist">Videos</v-tab> -->
                <!-- <v-tab to="/betsy">Betsy</v-tab> -->
                <v-tab

                  to="/footageuploads"
                  >Cameras</v-tab
                >
                <v-tab
          
                  to="/animal-dashboard"
                  >Animals</v-tab
                >
                <v-tab
            
                  to="/health-dashboard"
                  >Health</v-tab
                >
                <v-tab
           
                  to="/measurements-dashboard"
                  >Measurements</v-tab
                >
                <v-tab
     
                  to="/phenotype-dashboard"
                  >Phenotypes</v-tab
                >
              </v-tabs>
            </v-row>
            <home v-if="this.$route.name === 'home'" />
            <footage-uploads v-if="this.$route.name === 'footageuploads'" />
            <livestock-dashboard
              v-if="this.$route.name === 'animal_dashboard'"
            />
            <health-dashboard v-if="this.$route.name === 'health_dashboard'" />
            <measurement-dashboard
              v-if="this.$route.name === 'measurements_dashboard'"
            />
            <phenotype-dashboard
              v-if="this.$route.name === 'phenotype_dashboard'"
            />
          </v-card>
          <media_chart_row v-if="this.$route.name === 'home'"  />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import Home from "@/components/Home.vue";
import FootageUploads from "@/components/FootageUploads.vue";
import LivestockDashboard from "@/components/LivestockDashboard.vue";
import MeasurementDashboard from "@/components/MeasurementDashboard.vue";
import PhenotypeDashboard from "@/components/PhenotypeDashboard.vue";
import HealthDashboard from "@/components/HealthDashboard.vue";
import media_chart_row from "../components/media_chart_row.vue";

export default {
  components: {
    navbar,
    sidenav,
    Home,
    FootageUploads,
    LivestockDashboard,
    MeasurementDashboard,
    HealthDashboard,
    PhenotypeDashboard,
    media_chart_row
  },
  computed: {

    customer_id() {
      return this.$store.getters.getCustomerID;
    },
  },
  watch: {
    customer_id() {
      if (this.customer_id) {
        this.$store.dispatch("DDB_GET_ANIMAL_STATS");
        this.$store.dispatch("DDB_GET_TREATMENT_STATS");
        this.$store.dispatch("DDB_GET_MEASUREMENT_STATS")
        this.$store.dispatch("DDB_GET_PHENOTYPE_STATS")
      }
    },
  },
  mounted() {
    if (this.customer_id) {
      this.$store.dispatch("DDB_GET_ANIMAL_STATS");
      this.$store.dispatch("DDB_GET_TREATMENT_STATS");
      this.$store.dispatch("DDB_GET_MEASUREMENT_STATS")
      this.$store.dispatch("DDB_GET_PHENOTYPE_STATS")
    }
  },
};
</script>

    